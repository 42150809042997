<!--================Home Banner Area =================-->
<section class="banner_area ">
		<div class="banner_inner overlay d-flex align-items-center">
			<div class="container">
				<div class="banner_content text-left">
					<div class="page_link">
						<a href="/home">Home</a>
						<a href="/aboutus">About Us</a>
					</div>
					<h2>About Us</h2>
				</div>
			</div>
		</div>
	</section>
	<!--================End Home Banner Area =================-->

    	<!--================ Start Project Details Area =================-->
	<section class="project-details section-gap">
            <div class="container">
                    <div class="row justify-content-center">
                            <div class="col-lg-10 text-center">
                                <div class="section-title">
                                    <!-- <p>We Are Providing these services</p> -->
                                 
                                    <h1>About Q & P </h1>
                                    <!-- <h1>We Are <span>Q&P.</span> provide QUALITY and PRODUCTIVE IT Solutions and Services to customers.</h1> -->
                                </div>
                            </div>
                        </div>
                <div class="row">
                       
                    <div class="col-lg-6 col-md-6">
                        <div class="service-img">
                            <img class="img-fluid" src="../assets/img/about.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6">
                        <!-- single service -->
                        <div class="single-details">
                            <!-- <h2>About Q & P </h2> -->
                            
                            <p>We Are Q&P. provide QUALITY and PRODUCTIVE IT Solutions and Services to customers.</p>
                           
                            <!-- <ul class="list">
                                <li><span>Rating</span>: <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i
                                     class="fa fa-star"></i></li>
                                <li><span>Client</span>: Envato</li>
                                <li><span>Website</span>: Themeforest.net</li>
                                <li><span>Completed</span>: 17th Nov, 2018</li>
                            </ul>
                            <ul class="list social_details">
                                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fa fa-behance"></i></a></li>
                                <li><a href="#"><i class="fa fa-dribbble"></i></a></li>
                                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                            </ul> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--================ End Project Details Area =================-->
    