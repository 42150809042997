<!--================Home Banner Area =================-->
<section class="banner_area ">
    <div class="banner_inner overlay d-flex align-items-center">
        <div class="container">
            <div class="banner_content text-left">
                <div class="page_link">
                    <a href="/">Home</a>
                    <a href="/contact">Contact</a>
                </div>
                <h2>Contact Us</h2>
            </div>
        </div>
    </div>
</section>
<!--================End Home Banner Area =================-->



<!--================Contact Area =================-->
<section class="contact_area ">
        <div class="container">
            <!-- <div id="mapBox" class="mapBox" data-lat="40.701083" data-lon="-74.1522848" data-zoom="13" data-info="PO Box CT16122 Collins Street West, Victoria 8007, Australia."
                data-mlat="40.701083" data-mlon="-74.1522848">
            </div> -->
            <div class="row justify-content-center">
                <div class="col-lg-10 text-center">
                    <div class="section-title">
                        <p></p>
                        <h1>Contact</h1>
                        <!-- <p>POSITIVE TRAIN CONTROL (PTC)</p> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11997.364894188677!2d-96.1105359!3d41.2579058!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x47c05a18ae71e96d!2sQ%26P%20Technologies!5e0!3m2!1sen!2sin!4v1603479187279!5m2!1sen!2sin" width="100%" 
                    height="450" 
                    frameborder="0" 
                    style="border:0;" 
                    allowfullscreen="" 
                    aria-hidden="false" 
                    tabindex="0">
                    </iframe>
                </div>
                <div class="col-lg-6 col-md-6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15225.69600586844!2d78.389496!3d17.4394088!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1fdf78f7adb58f4!2sQ%26P%20Technologies%20LLC!5e0!3m2!1sen!2sin!4v1603479280983!5m2!1sen!2sin" width="100%" 
                    height="450" 
                    frameborder="0" 
                    style="border:0;" 
                    allowfullscreen="" 
                    aria-hidden="false" 
                    tabindex="0">
                    </iframe>
                </div>
            </div>

            <div class="row section-gap">
                    <div class="col-lg-1"></div>
                <div class="col-lg-4">
                    <div class="contact_info">
                        <div class="info_item">
                            <i class="lnr lnr-home"></i>
                            <span>Where to Find in US</span>
                            <h6> 1299 Farnam Street<br>
                                Suite 300, Omaha,<br>
                                Nebraska 68102</h6>
                            <p>Q&P Technologies</p>
                        </div>
                        <div class="info_item">
                            <i class="lnr lnr-home"></i>
                            <span>Where to Find in India</span>

                            <h6> #107 Kavuri Hills Road,<br/> Hitech City,
                                Hyderabad, <br/>Telangana - 500033</h6>
                            <p>Meema Q&P Technologies</p>
                        </div>
                        <div class="info_item">
                            <i class="lnr lnr-phone-handset"></i>
                            <h6><a href="#">Mobile: +1 402 404 5046<br>
                                Phone : +91 9100544109<br>
                                Fax:  +1 402 216 0911</a></h6>
                            <p></p>
                        </div>
                        <div class="info_item">
                            <i class="lnr lnr-envelope"></i>
                            <h6><a href="#">support@qnptechnologies.com</a></h6>
                            <p>Send us your query anytime!</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <!-- <form class="row contact_form" id="contactForm"
                        novalidate="novalidate">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" class="form-control" id="name" name="name" placeholder="Enter your name">
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control" id="email" name="email" placeholder="Enter email address">
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" id="subject" name="subject" placeholder="Enter Subject">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <textarea class="form-control" name="message" id="message" rows="1" placeholder="Enter Message"></textarea>
                            </div>
                        </div>
                        <div class="col-md-12 text-right">
                            <button type="submit" value="submit" class="primary-btn">Send Message</button>
                        </div>
                    </form> -->
                
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit(contactForm.value)" class="form" novalidate>
              
                        <mat-form-field class="form-element">
                            <input matInput placeholder="Name" minlength="5" maxlength="50" formControlName="name" required>
                            <!-- <mat-error *ngIf="!name.valid && name.touched"> 
                              {{ titleAlert }}
                            </mat-error> -->
                        </mat-form-field>
                    
                      <mat-form-field  class="form-element">
                        <input matInput placeholder="Email id" formControlName="email" required>
                        <!-- <mat-error *ngIf="!contactForm.controls['email'].valid && contactForm.controls['email'].touched">
                          {{ getErrorEmail() }}
                        </mat-error> -->
                      </mat-form-field>
                  
                      <mat-form-field class="form-element">
                        <input matInput placeholder="Phone number" formControlName="phonenumber" required>
                        <!-- <mat-error *ngIf="!contactForm.controls['phonenumber'].valid && contactForm.controls['phonenumber'].touched">
                          {{ getErrorPhonenumber() }}
                        </mat-error> -->
                      </mat-form-field>
                  
                      <mat-form-field class="form-element">
                        <input matInput placeholder="Description" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="50" formControlName="description" required>
                        <!-- <mat-error *ngIf="!contactForm.controls['description'].valid && contactForm.controls['description'].touched">
                          Required field, must be between 5 and 50 characters.
                        </mat-error> -->
                      </mat-form-field>
    
                      <div class="form-element">
                        <button mat-raised-button  class="full-width btn--primary" [disabled]="!contactForm.valid">Submit</button>
                      </div>
                  
                  
                    </form>

                </div>
                <div class="col-lg-1"></div>
            </div>
        </div>
    </section>
    <!--================Contact Area =================-->