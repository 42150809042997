<section class="home-banner-area">
		<div class="container">
			<div class="row justify-content-end fullscreen">
				<div class="col-lg-6 col-md-12 home-banner-left d-flex fullscreen align-items-center">
					<div>
                            <div class="logo">
									<a routerLink="/">
										<img src="../assets/img/bluelogo.jpg" class="logoleft" style="margin-top: -9.5rem;" width="35%" alt="">
									</a>
								</div>
						<!-- //login form	 -->
                            <div class="login-container">
                                    <div class="login-content">
                                      <mat-toolbar class="login-toolbar" style="background-color:#1397d5" color="primary">Login</mat-toolbar>
                                      <mat-card>
                                        <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">
                                          <mat-form-field>
                                            <mat-icon matPrefix>person_outline</mat-icon>
                                            <input autofocus type="text" matInput placeholder="Username" formControlName="username">
                                          </mat-form-field>
                              
                                          <mat-form-field>
                                            <mat-icon matPrefix>lock_outline</mat-icon>
                                            <input type="password" autocomplete="false" matInput placeholder="Password" formControlName="password">
                                          </mat-form-field>

                                          <a href="" ><span style="text-align: right">Forgot your password?</span></a>
                              
                                          <button type="submit" style="background-color:#1397d5" mat-raised-button color="primary">Submit</button>
                                        </form>
                                      </mat-card>
                                    </div>
                                  </div>
						
					</div>
				</div>
				<div class="col-lg-6 col-md-12 no-padding home-banner-right d-flex fullscreen align-items-end">
					<!-- <img class="img-fluid" src="../assets/img/header-img.png" alt=""> -->
				</div>
			</div>
		</div>
	</section>