import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { DataService } from '../data.service';
import { ContactService } from './contact.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  titleAlert: string = 'This field is required'
  submitted :boolean = true;
  req: object = {};
  data: any;


  constructor(private formBuilder: FormBuilder, private contactService: ContactService, private dataService: DataService) { }

  ngOnInit(): void {
    this.createForm();
  }


  createForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.contactForm = this.formBuilder.group({
      'email': [null, [Validators.required, Validators.pattern(emailregex)], this.checkInUseEmail],
      'name': [null, Validators.required,],
      'phonenumber':[null, Validators.pattern(/^[3-9]\d{9}$/)],
      'description': [null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      'validate': ''
    });
  }

  
  checkInUseEmail(control) {
    // mimic http database access
    let db = ['tony@gmail.com'];
    return new Observable(observer => {
      setTimeout(() => {
        let result = (db.indexOf(control.value) !== -1) ? { 'alreadyInUse': true } : null;
        observer.next(result);
        observer.complete();
      }, 4000)
    })
  }
 

  
  getErrorEmail() {
    return this.contactForm.get('email').hasError('required') ? 'Field is required' :
      this.contactForm.get('email').hasError('pattern') ? 'Not a valid email id' :
        this.contactForm.get('email').hasError('alreadyInUse') ? 'This email id is already in use' : '';
  }

  getErrorPhonenumber() {
    return this.contactForm.get('phonenumber').hasError('required') ? 'Field is required' :
      this.contactForm.get('phonenumber').hasError('pattern') ? 'Not a valid phone number' : '';
  }

  setChangeValidate() {
    this.contactForm.get('validate').valueChanges.subscribe(
      (validate) => {
        if (validate == '1') {
          this.contactForm.get('name').setValidators([Validators.required, Validators.minLength(3)]);
          this.titleAlert = "You need to specify at least 3 characters";
        } else {
          this.contactForm.get('name').setValidators(Validators.required);
        }
        this.contactForm.get('name').updateValueAndValidity();
      }
    )
  }

  onSubmit(data){
    debugger
    this.req= {
      "Name": data.name,
      "EmailId": data.email,
      "PhoneNumber": data.phonenumber,
      "Description": data.description
      
    }
    this.dataService.Post("api/EmailService/SendGeneralMail",this.req).
    subscribe(
      (res: any) => {
        
        //if (res) {
          alert("Email Sent Successfully");
          // toastr.success("Email Sent Successfully");
          this.contactForm.reset();
        // } else {
        //   toastr.error("Failed");
        //   // this.contactForm.reset();
        // }
        
      },
    );
  }

  reset() {
    this.createForm();
  }
  
}
