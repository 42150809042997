import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DataService } from '../data.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  CandidateInfo: string;

  constructor(private _http: Http, private httpclient: HttpClient, private dataService: DataService) {
    
      this.CandidateInfo="api/EmailService/"
     }
     FormMail(req){
      //write api url here
      return this.dataService.Post(this.CandidateInfo+"SendGeneralMail",req);
 }


}
