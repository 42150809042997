import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ExpertiseComponent } from './expertise/expertise.component';
import { IndustriesComponent } from './industries/industries.component';
import { ServicesComponent } from './services/services.component';
import { TechnologiesComponent } from './technologies/technologies.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
 {path:'', component:HomeComponent},
  { path: '', redirectTo: '/home', pathMatch: 'full' },
 {path:'home', component:HomeComponent},
 {path:'aboutus', component:AboutusComponent},
 {path:'expertise', component:ExpertiseComponent},
  {path:'industries', component:IndustriesComponent},
 {path:'services', component:ServicesComponent},
 {path:'technologies', component:TechnologiesComponent},
 {path:'contact', component:ContactComponent},
 {path:'login', component:LoginComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
