<!--================Home Banner Area =================-->
<section class="banner_area ">
        <div class="banner_inner overlay d-flex align-items-center">
            <div class="container">
                <div class="banner_content text-left">
                    <div class="page_link">
                        <a href="/">Home</a>
                        <a href="/industries">Industries</a>
                    </div>
                    <h2>Idustries</h2>
                </div>
            </div>
        </div>
    </section>
    <!--================End Home Banner Area =================-->
    
    <!--================ Start Team Area =================-->
    <section class="team-area">
        <div class="container">
                <div class="row justify-content-center">
                        <div class="col-lg-10 text-center">
                            <div class="section-title">
                                <p></p>
                                <h1>Industries</h1>
                                <!-- <p>POSITIVE TRAIN CONTROL (PTC)</p> -->
                            </div>
                        </div>
                    </div>
            <div class="owl-carousel active-team-carusel">
                <!-- single carousel -->
                <div class="row align-items-center single-item">
                    <div class="col-lg-6 col-md-6">
                        <div class="team-left">
                            <img class="img-fluid" src="../assets/img/project-details.jpg" alt="">
                            <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                <div class="m-title">
                                    <h4>Randy Weaver</h4>
                                    <p>Senior Barrister at law</p>
                                </div>
                                <div class="m-social">
                                    <div class="t-icons">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                    </div>
                                    <div class="b-icons">
                                        <a href="#"><i class="fa fa-dribbble"></i></a>
                                        <a href="#"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                        <h1>Transportation and Logistics</h1>
                        <p>
                                Transportation is defined as the movement of people, animals and goods from one 
                                location to another. Modes of transport include rail, road, water.
                        </p>
                        <p>Transportation is the driver of logistics, but logistics is the race car driver in the seat of transportation.</p>
                       
                        <p>Logistics requires planning, transportation is just the mode to execute the planning, when getting freight from point A to point B.</p>
                        <a class="primary-btn" href="#">view members</a>
                    </div>
                </div>
                <!-- single carousel -->
                <div class="row align-items-center single-item">
                    <div class="col-lg-6 col-md-6">
                        <div class="team-left">
                            <img class="img-fluid" src="../assets/img/solution.png" alt="">
                            <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                <div class="m-title">
                                    <h4>Randy Weaver</h4>
                                    <p>Senior Barrister at law</p>
                                </div>
                                <div class="m-social">
                                    <div class="t-icons">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                    </div>
                                    <div class="b-icons">
                                        <a href="#"><i class="fa fa-dribbble"></i></a>
                                        <a href="#"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                        <h1>BFSI (Banking Financial Services and Insurance)</h1>
                        <p>
                                The Banking, Financial Services and Insurance (BFSI) industry plays a key role in the financial wellbeing and progress of not only individuals and businesses, but also of nations as a whole.
                        </p>
                        <p>
                                Stringent regulatory requirements, increasing threat of cybercrime and data theft, changing business dynamics and rising customer expectations characterize the highly-competitive BFSI sector.
                        </p>
                        <a class="primary-btn" href="#">view members</a>
                    </div>
                </div>
                 <!-- single carousel -->
                 <div class="row align-items-center single-item">
                        <div class="col-lg-6 col-md-6">
                            <div class="team-left">
                                <img class="img-fluid" src="../assets/img/requirement.jpg" alt="">
                                <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                    <div class="m-title">
                                        <h4>Randy Weaver</h4>
                                        <p>Senior Barrister at law</p>
                                    </div>
                                    <div class="m-social">
                                        <div class="t-icons">
                                            <a href="#"><i class="fa fa-facebook"></i></a>
                                            <a href="#"><i class="fa fa-twitter"></i></a>
                                        </div>
                                        <div class="b-icons">
                                            <a href="#"><i class="fa fa-dribbble"></i></a>
                                            <a href="#"><i class="fa fa-behance"></i></a>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                            <h1>Healthcare</h1>
                            <p>
                                    Railway companies pioneered the disciplines of industrial medicine and occupational safety and health. 
                                    They were the first organisations to give physical examinations to prospective employees.
                            </p>
                            <p>
                                    The motivation was partly to prevent fraudulent claims for pre-existing conditions, but also to improve rail safety
                            </p>
                            <p>
                                    Railway companies increasingly improved the health of their employees with preventative measures against disease. Vaccination schemes were introduced.
                            </p>
                           
                            
                            <a class="primary-btn" href="#">view members</a>
                        </div>
                    </div>
                 
            </div>
    
            <p></p>
        </div>
    </section>
    <!--================ End Team Area =================-->