	<!--================ start banner Area =================-->
	<section class="home-banner-area">
		<div class="container">
			<div class="row justify-content-end fullscreen">
				<div class="col-lg-6 col-md-12 home-banner-left d-flex fullscreen align-items-center">
					<div>
							<div class="logo">
									<a routerLink="/">
										<img src="../assets/img/bluelogo.jpg" style="margin-top: -9.5rem;" width="35%" alt="">
									</a>
								</div>
						<h1 >
							We Provide <br><span>Quality & Productive</span> <br>
							Services 
						</h1>
						<!-- <p class="mx-auto mb-40">
							OVER 40 YEARS OF RAILROAD AND PTC EXPERIENCE
						</p> -->
						<a href="#" class="primary-btn">More About Us</a>

						<div class="d-flex align-items-center mt-60">
							<!-- <a id="play-home-video" class="video-play-button" href="https://www.youtube.com/watch?v=vParh5wE-tM"> -->
								<a id="play-home-video" class="video-play-button">
								<span></span>
							</a>
							<div class="watch_video">
								<h5>Our video</h5>
								<p>You will love our execution</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6 col-md-12 no-padding home-banner-right d-flex fullscreen align-items-end">
					<img class="img-fluid" src="../assets/img/header-img.png" alt="">
				</div>
			</div>
		</div>
	</section>
	<!--================ End banner Area =================-->

	<!--================ Start Brand Area =================-->
	<section class="brand-area">
		<div class="container-fluid">
			<div class="row justify-content-start">
				<div class="col-lg-6">
					<div class="brand-carousel owl-carousel">
						<!-- single-brand -->
						<div class="single-brand-item d-table">
							<div class="d-table-cell">
								<img src="../assets/img/brands/b1.png" alt="">
							</div>
						</div>
						<!-- single-brand -->
						<div class="single-brand-item d-table df-height">
							<div class="d-table-cell">
								<img src="../assets/img/brands/b2.png" alt="">
							</div>
						</div>
						<!-- single-brand -->
						<div class="single-brand-item d-table">
							<div class="d-table-cell">
								<img src="../assets/img/brands/b3.png" alt="">
							</div>
						</div>
						<!-- single-brand -->
						<div class="single-brand-item d-table df-height">
							<div class="d-table-cell">
								<img src="../assets/img/brands/b4.png" alt="">
							</div>
						</div>
						<!-- single-brand -->
						<div class="single-brand-item d-table">
							<div class="d-table-cell">
								<img src="../assets/img/brands/b2.png" alt="">
							</div>
						</div>
						<!-- single-brand -->
						<div class="single-brand-item d-table df-height">
							<div class="d-table-cell">
								<img src="../assets/img/brands/b1.png" alt="">
							</div>
						</div>
						<!-- single-brand -->
						<div class="single-brand-item d-table">
							<div class="d-table-cell">
								<img src="../assets/img/brands/b2.png" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--================ End Brand Area =================-->
	
	<!--================ Start Service Area =================-->
	<section class="service-area section-gap">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-9 text-center">
					<div class="section-title">
						<!-- <p>We Providing these services</p> -->
						<h1>We Provide These <br> Services to Our Customers</h1>
					</div>
				</div>
			</div>
			
			<section class="container regular slider">
				<div>
					<div class="single-service">
						<div class="service-icon">
							<img  src="../assets/img/services2.webp" alt="">
							<!-- <img class="s-icon" src="../assets/img/service/icon1.png" alt="">
							<img class="clr-icon" src="../assets/img/service/clr-icon1.png" alt=""> -->
						</div>
						<div class="service-content">
							<h4>Quality Assurance</h4>
							<input type="checkbox" class="read-more-state" id="post-1" />
							<p class="read-more-wrap" style="text-align:justify">We offer full cycle quality assurance and application testing. 
								We add value to your quality assurance practices by<span class="read-more-target"> embedding domain 
								expertise and quality into your processes. We focus on increasing the level of 
								quality while reducing the overall costs associated with process outputs.</span></p>
								<label for="post-1" class="read-more-trigger"></label>
						</div>
					</div>
				</div>
				<div>
					<div class="single-service">
						<div class="service-icon">
							<img  src="../assets/img/services1.webp" alt="">
							<!-- <img class="s-icon" src="../assets/img/service/icon2.png" alt="">
							<img class="clr-icon" src="../assets/img/service/clr-icon2.png" alt=""> -->
						</div>
						<div class="service-content">
							<h4>Software Development Automation</h4>
							<input type="checkbox" class="read-more-state" id="post-2" />
							<p class="read-more-wrap" style="text-align:justify">We build software solutions based on Continuous Delivery, a set of principles and <span class="read-more-target"> practices 
								using Lean and heavy  automation. By automating traditionally  manual processes, 
								We empower businesses to focus on growth, collaborating to fulfill technical needs in a 
								way that consolidate complex workflows into a simple handoff.</span></p>
								<label for="post-2" class="read-more-trigger"></label>
						</div>
					</div>
				</div>
				<div>
					<div class="single-service">
						<div class="service-icon">
							<img src="../assets/img/services3.jpg" alt="">
							<!-- <img class="s-icon" src="../assets/img/service/icon3.png" alt="">
							<img class="clr-icon" src="../assets/img/service/clr-icon3.png" alt=""> -->
						</div>
						<div class="service-content">
							<h4>DevOps</h4>
							<input type="checkbox" class="read-more-state" id="post-3" />
							<p class="read-more-wrap" style="text-align:justify">Our group of Engineers (developers, system admins, QA’s. Testers etc turned into 
								DevOps Engineers) will hold end to <span class="read-more-target"> end responsibility of the Application (Software) 
								right from gathering the requirement to development, to testing, to infrastructure 
								deployment, to application deployment and finally monitoring & gathering feedback 
								from the end users, then again implementing the changes.</span></p>
								<label for="post-3" class="read-more-trigger"></label>
						</div>
					</div>
				</div>
				<div>
					<div class="single-service">
						<div class="service-icon">
							<img  src="../assets/img/service-4.jpg" alt="">
							<!-- <img class="s-icon" src="../assets/img/service/icon1.png" alt=""> -->
							<!-- <img class="clr-icon" src="../assets/img/service/clr-icon1.png" alt=""> -->
						</div>
						<div class="service-content">
								<h4>Digital Marketing & Solutions</h4>
								<input type="checkbox" class="read-more-state" id="post-4" />
							<p class="read-more-wrap" style="text-align:justify">Q&P, Digital Marketing Agency aims to define a tight, digital strategy that focuses on achieving your key <span class="read-more-target">commercial goals. Raising your profile, boosting revenue,  increasing efficiency, reducing cost, training your staff and communicating better with your customers are only a few of the business priorities vital to any company’s success. In order to avoid spending extra money for little return, you need a complex yet effective strategy.</span></p>
							<label for="post-4" class="read-more-trigger"></label>	
						</div>
					</div>
				</div>
				<div>
					<div class="single-service">
						<div class="service-icon">
							<img  src="../assets/img/service-5-iot.jpg" alt="">
							<!-- <img class="s-icon" src="../assets/img/service/icon2.png" alt="">
							<img class="clr-icon" src="../assets/img/service/clr-icon2.png" alt=""> -->
						</div>
						<div class="service-content">
								<h4>Internet Of Things</h4>
								<input type="checkbox" class="read-more-state" id="post-5" />
								<p class="read-more-wrap" style="text-align:justify">Q&P, Specializes in building smart solutions that empower enterprises to securely capture, combine and <span class="read-more-target">transform data into actionable insight. We leverage our expertise to deliver solutions to clients developing connected products, and implements Cloud enabled IoT technologies.
									With extensive experience in embedded system design and development.</span>
									
								</p><label for="post-5" class="read-more-trigger"></label>
							</div>
					</div>
				</div>

				<div>
						<div class="single-service">
							<div class="service-icon">
								<img  src="../assets/img/service-recruitment.png" alt="">
								<!-- <img class="s-icon" src="../assets/img/service/icon2.png" alt="">
								<img class="clr-icon" src="../assets/img/service/clr-icon2.png" alt=""> -->
							</div>
							<div class="service-content">
									<h4>IT Recruitment & Staffing</h4>
									<input type="checkbox" class="read-more-state" id="post-6" />
									<p class="read-more-wrap" style="text-align:justify">Q&P, provide Recruitment and Staffing services to many industries and domain through our innovative and customized 
										<span class="read-more-target"> solutions and passionate commitment to research. Ability to understand the hiring strategies, availability of talent and compensation benchmarking makes us proud hiring partner for various industries</span>
										
									</p>
									<label for="post-6" class="read-more-trigger"></label>
								</div>
						</div>
					</div>
				<div>
				  <!-- <img src="http://placehold.it/350x300?text=6"> -->
				</div>
			  </section>
		</div>
	</section>
	<!--================ End Service Area =================-->

	<!--================ Start Video Area =================-->
	<!-- <section class="video-sec-area">
		<div class="container">
			<div class="row justify-content-start align-items-center">
				<div class="col-lg-6 video-left justify-content-center align-items-center d-flex">
					<div class="overlay overlay-bg"></div>
					<a id="play-home-video" class="video-play-button" href="https://www.youtube.com/watch?time_continue=2&v=J9YzcEe29d0">
						<span></span>
					</a>
				</div>
				<div class="col-lg-5 offset-lg-1 video-right">
					<h1>We Are Crafted. <br>
						Some Info About Us</h1>
					<p>
						In the history of modern astronomy, there is probably no one greater leap forward than the building and launch of
						the space telescope known as the Hubble.
					</p>

					<div class="counter_area" id="project_counter">
						
						<div class="single_counter">
							<div class="info-content">
								<h4><span class="counter">500</span>+</h4>
								<p>Qualified Lawyer</p>
							</div>
						</div>
						
						<div class="single_counter">
							<div class="info-content">
								<h4><span class="counter">20,650</span>+</h4>
								<p>Solved Cases</p>
							</div>
						</div>
						
						<div class="single_counter">
							<div class="info-content">
								<h4><span class="counter">2.5</span>k+</h4>
								<p>Trusted Clients</p>
							</div>
						</div>
						
						<div class="single_counter">
							<div class="info-content">
								<h4><span class="counter">50</span>+</h4>
								<p>Achievements</p>
							</div>
						</div>
					</div>
				</div>
				<img class="img-fluid video-shape" src="../assets/img/video-bg-shape.png" alt="">
			</div>
		</div>
	</section> -->
	<!--================ End video Area =================-->

	
<!--================ Start Team Area =================-->
<section class="team-area">
    <div class="container">
            <div class="row justify-content-center">
                    <div class="col-lg-10 col-sm-10 text-center">
                        <div class="section-title">
                            <p></p>
                            <h1>TRAIN CONTROL SYSTEMS</h1>
                            <!-- <p>POSITIVE TRAIN CONTROL (PTC)</p> -->
                        </div>
                    </div>
                </div>
        <div class="owl-carousel active-team-carusel">
            <!-- single carousel -->
            <div class="row align-items-center single-item">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="team-left">
                        <img class="img-fluid" src="../assets/img/pos.jpeg" alt="">
                        <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                            <div class="m-title">
                                <h4>Randy Weaver</h4>
                                <p>Senior Barrister at law</p>
                            </div>
                            <div class="m-social">
                                <div class="t-icons">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                </div>
                                <div class="b-icons">
                                    <a href="#"><i class="fa fa-dribbble"></i></a>
                                    <a href="#"><i class="fa fa-behance"></i></a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-5 offset-lg-1 col-md-6 col-sm-6 team-right">
                    <h1>Positive Train Control (PTC)</h1>
                    <p>
                            Currently Positive Train Control (PTC) implementation and support, 
                            is the highest priority for railroads in North America. PTC is a government-regulated 
                            System that improves safety in train movements and prevents incidents like train to train 
                            collisions and over speed derailments.
                    </p>
                    <p>We at Q & P Knowledgeable and Technical resources to implement and support PTC.</p>
                   
                            <p> Out goal is  to provide 24/7 support on PTC to run operation safe and fast.</p>
                    <!-- <a class="primary-btn" href="#">view members</a> -->
                </div>
            </div>
            <!-- single carousel -->
            <div class="row align-items-center single-item">
                <div class="col-lg-6 col-md-6">
                    <div class="team-left">
                        <img class="img-fluid" src="../assets/img/solution.png" alt="">
                        <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                            <div class="m-title">
                                <h4>Randy Weaver</h4>
                                <p>Senior Barrister at law</p>
                            </div>
                            <div class="m-social">
                                <div class="t-icons">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                </div>
                                <div class="b-icons">
                                    <a href="#"><i class="fa fa-dribbble"></i></a>
                                    <a href="#"><i class="fa fa-behance"></i></a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                    <h1>Solution</h1>
                    <p>
                            We offer Quality and Productive Positive Train Control (PTC) Testing and Operations Support. Our main focus is on delivering of Quality work. Swiftness is achieved by Automation and Quality is achieved by extensive training on PTC and railroad operations.
                    </p>
                    <p>
                            We have special training plan on Positive Train Control (PTC). Every associate working for the project will get complete training on all components of PTC (Onboard, Back Office, Dispatching System, Track Data, Other tools used for Analysis) and be ready to support PTC testing and operations.
                    </p>
                    <!-- <a class="primary-btn" href="#">view members</a> -->
                </div>
            </div>
             <!-- single carousel -->
             <div class="row align-items-center single-item">
                    <div class="col-lg-6 col-md-6">
                        <div class="team-left">
                            <img class="img-fluid" src="../assets/img/requirement.jpg" alt="">
                            <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                <div class="m-title">
                                    <h4>Randy Weaver</h4>
                                    <p>Senior Barrister at law</p>
                                </div>
                                <div class="m-social">
                                    <div class="t-icons">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                    </div>
                                    <div class="b-icons">
                                        <a href="#"><i class="fa fa-dribbble"></i></a>
                                        <a href="#"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                        <h1>Requirements gathering/ Planning/Design</h1>
                        <!-- <p>
                                Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis. Sunt suscipit voluptas ipsa in tempora esse soluta sint.
                        </p> -->

                        <!-- <P>COMING SOON</P> -->
                        
                        <!-- <a class="primary-btn" href="#">view members</a> -->
                    </div>
                </div>
                <!-- single carousel -->
                <div class="row align-items-center single-item">
                    <div class="col-lg-6 col-md-6">
                        <div class="team-left">
                            <img class="img-fluid" src="../assets/img/testing.png" alt="">
                            <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                <div class="m-title">
                                    <h4>Randy Weaver</h4>
                                    <p>Senior Barrister at law</p>
                                </div>
                                <div class="m-social">
                                    <div class="t-icons">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                    </div>
                                    <div class="b-icons">
                                        <a href="#"><i class="fa fa-dribbble"></i></a>
                                        <a href="#"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                        <h1>Testing (Manual and Automation)</h1>
                        <p>
                                 Onboard Testing
                        </p>
                        <p>
                                Back Office System Testing
                        </p>
                        <p>
                                Computer Aided Dispatching System Testing
                        </p>
                        <p>
                                Track Data Testing
                        </p>
                        <p>
                                End to End testing with all components Integrated in lab
                        </p>
                        <p>
                            Operational Scenario based Testing
                        </p>
                        <p>
                            Lab Interoperability Testing
                        </p>
                        <p>
                            Any Other Internal Systems that support PTC
                        </p>
                        <!-- <a class="primary-btn" href="#">view members</a> -->
                    </div>
                </div>
                 <!-- single carousel -->
             <div class="row align-items-center single-item">
                    <div class="col-lg-6 col-md-6">
                        <div class="team-left">
                            <img class="img-fluid" src="../assets/img/operating.png" alt="">
                            <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                <div class="m-title">
                                    <h4>Randy Weaver</h4>
                                    <p>Senior Barrister at law</p>
                                </div>
                                <div class="m-social">
                                    <div class="t-icons">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                    </div>
                                    <div class="b-icons">
                                        <a href="#"><i class="fa fa-dribbble"></i></a>
                                        <a href="#"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                        <h1>Implementation/ Operations Support (Manual and Automation)</h1>
                        <p>
                                Monitoring and Issues Analysis of Field Qualification/Field Integration Testing
                        </p>
                        <p>
                                PTC Operations Launch Monitoring and Issues Analysis
                        </p>
                        <p>
                                PTC Help desk tickets Analysis
                        </p>
                        <p>
                                Enforcement's Analysis
                        </p>
                        <p>
                                Analysis of any issues that occur in PTC Operations
                        </p>
                        <!-- <a class="primary-btn" href="#">view members</a> -->
                    </div>
                </div>
                <!-- single carousel -->
                <div class="row align-items-center single-item">
                    <div class="col-lg-6 col-md-6">
                        <div class="team-left">
                            <img class="img-fluid" src="../assets/img/reporting.png" alt="">
                            <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                <div class="m-title">
                                    <h4>Randy Weaver</h4>
                                    <p>Senior Barrister at law</p>
                                </div>
                                <div class="m-social">
                                    <div class="t-icons">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                    </div>
                                    <div class="b-icons">
                                        <a href="#"><i class="fa fa-dribbble"></i></a>
                                        <a href="#"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                        <h1>Reporting / Data Analytics</h1>
                        <p>
                                Automated Dashboard Reports for Management on PTC Implementation
                        <p>
                                Automated Reports on PTC Issues / Trends
                        </p>
                        <p>
                                Automated Issues Analysis
                        </p>
                        <p>
                                Automated System to assist PTC Help desk (Teir one support) at high level on PTC Ticket/Call received.
                        </p>
                        <!-- <a class="primary-btn" href="#">view members</a> -->
                    </div>
                </div>
        </div>

        <p></p>
    </div>
</section>
<!--================ End Team Area =================-->

	<!--================ Start Team Area =================-->
	<!-- <section class="team-area">
			<div class="container">
				<div class="owl-carousel active-team-carusel">
					
					<div class="row align-items-center single-item">
						<div class="col-lg-6 col-md-6">
							<div class="team-left">
								<img class="img-fluid" src="../assets/img/team1.jpg" alt="">
								<div class="member-desc d-flex justify-content-between align-items-center">
									<div class="m-title">
										<h4>Randy Weaver</h4>
										<p>Senior Barrister at law</p>
									</div>
									<div class="m-social">
										<div class="t-icons">
											<a href="#"><i class="fa fa-facebook"></i></a>
											<a href="#"><i class="fa fa-twitter"></i></a>
										</div>
										<div class="b-icons">
											<a href="#"><i class="fa fa-dribbble"></i></a>
											<a href="#"><i class="fa fa-behance"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-5 offset-lg-1 col-md-6 team-right">
							<h1>Our Interactive <br>
								Team Mates</h1>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
								magna aliqua. Ut enim ad minim veniam quis nostrud exercitation.
							</p>
							<a class="primary-btn" href="#">view members</a>
						</div>
					</div>
					
					<div class="row align-items-center single-item">
						<div class="col-lg-6 col-md-6">
							<div class="team-left">
								<img class="img-fluid" src="../assets/img/team1.jpg" alt="">
								<div class="member-desc d-flex justify-content-between align-items-center">
									<div class="m-title">
										<h4>Randy Weaver</h4>
										<p>Senior Barrister at law</p>
									</div>
									<div class="m-social">
										<div class="t-icons">
											<a href="#"><i class="fa fa-facebook"></i></a>
											<a href="#"><i class="fa fa-twitter"></i></a>
										</div>
										<div class="b-icons">
											<a href="#"><i class="fa fa-dribbble"></i></a>
											<a href="#"><i class="fa fa-behance"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-5 offset-lg-1 col-md-6 team-right">
							<h1>Our Interactive <br>
								Team Mates</h1>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
								magna aliqua. Ut enim ad minim veniam quis nostrud exercitation.
							</p>
							<a class="primary-btn" href="#">view members</a>
						</div>
					</div>
				</div>
			</div>
		</section> -->
		<!--================ End Team Area =================-->

	<!--================ Start Testimonial Area =================-->
	<!-- <section class="testimonial-area section-gap">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-10 text-center">
					<div class="section-title">
						<p>We Are Providing these services</p>
						<h1>We Are <span>Crafted.</span> Design & Development<br> <span>Services</span> Provider.</h1>
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-lg-8 text-center">
					<div class="owl-carousel active-testi-carousel">
						
						<div class="single-testi-item">
							<div class="author-title">
								<div class="thumb"><img src="../assets/img/about-author.png" alt=""></div>
								<div class="a-desc">
									<h6>Marvel Maison</h6>
									<p>Chief Executive, Amazon</p>
								</div>
							</div>
							<div class="author-desc">
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
									dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
									commodo consequat.</p>
							</div>
						</div>
						
						<div class="single-testi-item">
							<div class="author-title">
								<div class="thumb"><img src="../assets/img/about-author.png" alt=""></div>
								<div class="a-desc">
									<h6>Marvel Maison</h6>
									<p>Chief Executive, Amazon</p>
								</div>
							</div>
							<div class="author-desc">
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
									dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
									commodo consequat.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section> -->
	<!--================ End Testimonial Area =================-->

	<!--================ Start CTA Area =================-->
	<section class="cta-area">
		<div class="container">
			<div class="row">
				<div class="col-lg-7 col-md-12">
					<!-- <h2>Get to Know Project Estimate?</h2>
					<p>There is a moment in the life of any aspiring astronomer that it is time to buy that first telescope. It’s
						exciting to think about setting up your own viewing station whether that is on the deck</p>
					<a href="#" class="primary-btn">Get Estimate for Free</a> -->
					<h2>Coming soon</h2>
				</div>
				<img src="../assets/img/cta-bg-shape.png" alt="" class="cta-shape img-fluid">
			</div>
		</div>
	</section>
	<!--================ End CTA Area =================-->

	<!--================ Start Blog Area =================-->
	<section class="blog-area section-gap">
		<div class="container">
			<div class="row align-items-end justify-content-center">
				<div class="col-lg-5 col-md-12 text-left">
					<div class="section-title">
						<h1>Latest Posts<br> From Our Blog</h1>
						<!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
							magna aliqua. Ut enim ad minim veniam quis nostrud exercitation.</p> -->
					</div>
				</div>
				<div class="col-lg-5 col-md-12 offset-lg-2 text-right">
					<div class="blog-btn">
						<!-- <a href="#" class="primary-btn">Get Estimate for Free</a> -->
					</div>
				</div>
			</div>
			<div class="row blog-inner justify-content-center">
				<!-- single-blog -->
				<div class="col-lg-4 col-md-6 col-sm-6">
					<div class="single-blog">
						<div class="blog-thumb">
							<img class="img-fluid" src="../assets/img/blog/h-blog1.jpg" alt="">
						</div>
						<div class="blog-details">
							<div class="blog-meta">
								<!-- <span>25 june, 2018 | By Mark Wiens</span> -->
							</div>
							<h5><a href="#">Coming soon</a></h5>
							<!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
								magna aliqua. Ut enim ad minim veniam quis nostrud exercitation.</p> -->
						</div>
					</div>
				</div>
				<!-- single-blog -->
				<div class="col-lg-4 col-md-6 col-sm-6">
					<div class="single-blog">
						<div class="blog-thumb">
							<img class="img-fluid" src="../assets/img/blog/h-blog2.jpg" alt="">
						</div>
						<div class="blog-details">
							<div class="blog-meta">
								<!-- <span>25 june, 2018 | By Mark Wiens</span> -->
							</div>
							<h5><a href="#">Coming soon</a></h5>
							<!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
								magna aliqua. Ut enim ad minim veniam quis nostrud exercitation.</p> -->
						</div>
					</div>
				</div>
				<!-- single-blog -->
				<div class="col-lg-4 col-md-6 col-sm-6">
					<div class="single-blog">
						<div class="blog-thumb">
							<img class="img-fluid" src="../assets/img/blog/h-blog3.jpg" alt="">
						</div>
						<div class="blog-details">
							<div class="blog-meta">
								<!-- <span>25 june, 2018 | By Mark Wiens</span> -->
							</div>
							<h5><a href="#">Coming soon</a></h5>
							<!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
								magna aliqua. Ut enim ad minim veniam quis nostrud exercitation.</p> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!--================ End Blog Area =================-->
