<section class="banner_area ">
    <div class="banner_inner overlay d-flex align-items-center">
        <div class="container">
            <div class="banner_content text-left">
                <div class="page_link">
                    <a href="/home">Home</a>
                    <a href="/technologies">Technologies</a>
                </div>
                <h2>Technologies</h2>
            </div>
        </div>
    </div>
</section>
<!--================End Home Banner Area =================-->
  	<!--================ Start Project Details Area =================-->
      <section class="project-details section-gap">
        <div class="container">
                <div class="row justify-content-center">
                        <div class="col-lg-10 text-center">
                            <div class="section-title">
                                <!-- <p>We Are Providing these services</p> -->
                             

                                <h1>Coming Soon</h1>
                            </div>
                        </div>
                    </div>
            
        </div>
    </section>
    <!--================ End Project Details Area =================-->
