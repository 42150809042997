<!--================Home Banner Area =================-->
<section class="banner_area ">
    <div class="banner_inner overlay d-flex align-items-center">
        <div class="container">
            <div class="banner_content text-left">
                <div class="page_link">
                    <a href="/">Home</a>
                    <a href="/expertise">Expertise</a>
                </div>
                <h2>Expertise</h2>
            </div>
        </div>
    </div>
</section>
<!--================End Home Banner Area =================-->

<!--================ Start Team Area =================-->
<section class="team-area">
    <div class="container">
            <div class="row justify-content-center">
                    <div class="col-lg-10 text-center">
                        <div class="section-title">
                            <p></p>
                            <h1>TRAIN CONTROL SYSTEMS</h1>
                            <!-- <p>POSITIVE TRAIN CONTROL (PTC)</p> -->
                        </div>
                    </div>
                </div>
        <div class="owl-carousel active-team-carusel">
            <!-- single carousel -->
            <div class="row align-items-center single-item">
                <div class="col-lg-6 col-md-6">
                    <div class="team-left">
                        <img class="img-fluid" src="../assets/img/pos.jpeg" alt="">
                        <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                            <div class="m-title">
                                <h4>Randy Weaver</h4>
                                <p>Senior Barrister at law</p>
                            </div>
                            <div class="m-social">
                                <div class="t-icons">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                </div>
                                <div class="b-icons">
                                    <a href="#"><i class="fa fa-dribbble"></i></a>
                                    <a href="#"><i class="fa fa-behance"></i></a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                    <h1>Positive Train Control (PTC)</h1>
                    <p>
                            Currently Positive Train Control (PTC) implementation and support, 
                            is the highest priority for railroads in North America. PTC is a government-regulated 
                            System that improves safety in train movements and prevents incidents like train to train 
                            collisions and over speed derailments.
                    </p>
                    <p>We at Q & P Knowledgeable and Technical resources to implement and support PTC.</p>
                   
                            <p> Out goal is  to provide 24/7 support on PTC to run operation safe and fast.</p>
                    <!-- <a class="primary-btn" href="#">view members</a> -->
                </div>
            </div>
            <!-- single carousel -->
            <div class="row align-items-center single-item">
                <div class="col-lg-6 col-md-6">
                    <div class="team-left">
                        <img class="img-fluid" src="../assets/img/solution.png" alt="">
                        <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                            <div class="m-title">
                                <h4>Randy Weaver</h4>
                                <p>Senior Barrister at law</p>
                            </div>
                            <div class="m-social">
                                <div class="t-icons">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                </div>
                                <div class="b-icons">
                                    <a href="#"><i class="fa fa-dribbble"></i></a>
                                    <a href="#"><i class="fa fa-behance"></i></a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                    <h1>Solution</h1>
                    <p>
                            We offer Quality and Productive Positive Train Control (PTC) Testing and Operations Support. Our main focus is on delivering of Quality work. Swiftness is achieved by Automation and Quality is achieved by extensive training on PTC and railroad operations.
                    </p>
                    <p>
                            We have special training plan on Positive Train Control (PTC). Every associate working for the project will get complete training on all components of PTC (Onboard, Back Office, Dispatching System, Track Data, Other tools used for Analysis) and be ready to support PTC testing and operations.
                    </p>
                    <!-- <a class="primary-btn" href="#">view members</a> -->
                </div>
            </div>
             <!-- single carousel -->
             <div class="row align-items-center single-item">
                    <div class="col-lg-6 col-md-6">
                        <div class="team-left">
                            <img class="img-fluid" src="../assets/img/requirement.jpg" alt="">
                            <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                <div class="m-title">
                                    <h4>Randy Weaver</h4>
                                    <p>Senior Barrister at law</p>
                                </div>
                                <div class="m-social">
                                    <div class="t-icons">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                    </div>
                                    <div class="b-icons">
                                        <a href="#"><i class="fa fa-dribbble"></i></a>
                                        <a href="#"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                        <h1>Requirements gathering/ Planning/Design</h1>
                        <!-- <p>
                                Nemo cupiditate ab quibusdam quaerat impedit magni. Earum suscipit ipsum laudantium. Quo delectus est. Maiores voluptas ab sit natus veritatis ut. Debitis nulla cumque veritatis. Sunt suscipit voluptas ipsa in tempora esse soluta sint.
                        </p> -->

                        <!-- <P>COMING SOON</P> -->
                        
                        <!-- <a class="primary-btn" href="#">view members</a> -->
                    </div>
                </div>
                <!-- single carousel -->
                <div class="row align-items-center single-item">
                    <div class="col-lg-6 col-md-6">
                        <div class="team-left">
                            <img class="img-fluid" src="../assets/img/testing.png" alt="">
                            <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                <div class="m-title">
                                    <h4>Randy Weaver</h4>
                                    <p>Senior Barrister at law</p>
                                </div>
                                <div class="m-social">
                                    <div class="t-icons">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                    </div>
                                    <div class="b-icons">
                                        <a href="#"><i class="fa fa-dribbble"></i></a>
                                        <a href="#"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                        <h1>Testing (Manual and Automation)</h1>
                        <p>
                                 Onboard Testing
                        </p>
                        <p>
                                Back Office System Testing
                        </p>
                        <p>
                                Computer Aided Dispatching System Testing
                        </p>
                        <p>
                                Track Data Testing
                        </p>
                        <p>
                                End to End testing with all components Integrated in lab
                        </p>
                        <p>
                            Operational Scenario based Testing
                        </p>
                        <p>
                            Lab Interoperability Testing
                        </p>
                        <p>
                            Any Other Internal Systems that support PTC
                        </p>
                        <!-- <a class="primary-btn" href="#">view members</a> -->
                    </div>
                </div>
                 <!-- single carousel -->
             <div class="row align-items-center single-item">
                    <div class="col-lg-6 col-md-6">
                        <div class="team-left">
                            <img class="img-fluid" src="../assets/img/operating.png" alt="">
                            <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                <div class="m-title">
                                    <h4>Randy Weaver</h4>
                                    <p>Senior Barrister at law</p>
                                </div>
                                <div class="m-social">
                                    <div class="t-icons">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                    </div>
                                    <div class="b-icons">
                                        <a href="#"><i class="fa fa-dribbble"></i></a>
                                        <a href="#"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                        <h1>Implementation/ Operations Support (Manual and Automation)</h1>
                        <p>
                                Monitoring and Issues Analysis of Field Qualification/Field Integration Testing
                        </p>
                        <p>
                                PTC Operations Launch Monitoring and Issues Analysis
                        </p>
                        <p>
                                PTC Help desk tickets Analysis
                        </p>
                        <p>
                                Enforcement's Analysis
                        </p>
                        <p>
                                Analysis of any issues that occur in PTC Operations
                        </p>
                        <!-- <a class="primary-btn" href="#">view members</a> -->
                    </div>
                </div>
                <!-- single carousel -->
                <div class="row align-items-center single-item">
                    <div class="col-lg-6 col-md-6">
                        <div class="team-left">
                            <img class="img-fluid" src="../assets/img/reporting.png" alt="">
                            <!-- <div class="member-desc d-flex justify-content-between align-items-center">
                                <div class="m-title">
                                    <h4>Randy Weaver</h4>
                                    <p>Senior Barrister at law</p>
                                </div>
                                <div class="m-social">
                                    <div class="t-icons">
                                        <a href="#"><i class="fa fa-facebook"></i></a>
                                        <a href="#"><i class="fa fa-twitter"></i></a>
                                    </div>
                                    <div class="b-icons">
                                        <a href="#"><i class="fa fa-dribbble"></i></a>
                                        <a href="#"><i class="fa fa-behance"></i></a>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-5 offset-lg-1 col-md-6 team-right">
                        <h1>Reporting / Data Analytics</h1>
                        <p>
                                Automated Dashboard Reports for Management on PTC Implementation
                        <p>
                                Automated Reports on PTC Issues / Trends
                        </p>
                        <p>
                                Automated Issues Analysis
                        </p>
                        <p>
                                Automated System to assist PTC Help desk (Teir one support) at high level on PTC Ticket/Call received.
                        </p>
                        <!-- <a class="primary-btn" href="#">view members</a> -->
                    </div>
                </div>
        </div>

        <p></p>
    </div>
</section>
<!--================ End Team Area =================-->