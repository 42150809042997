<!--================ Offcanvus Menu Area =================-->
<div class="side_menu">
    <div class="logo">
        <a href="/">
            <!-- <img src="../assets/img/logo.png" alt=""> -->
            <h3 class="toggle_icon">Q & P</h3>
        </a>
    </div>
    <ul class="list menu-left">

        <li>
            <a class="toggle_icon" href="/home">Home</a>
        </li>
        <li>
            <a class="toggle_icon" href="/aboutus">About</a>
        </li>
        <li>
            <a class="toggle_icon" href="/expertise">Expertise</a>
        </li>
        <!-- <li>
            <a class="toggle_icon" href="/industries">Industries</a>
        </li> -->
        <li>
            <a class="toggle_icon" href="/services">Services</a>
        </li>
         <li>
            <a class="toggle_icon" href="/login">Login</a>
        </li>
        <!-- <li>
            <a class="toggle_icon" href="/technologies">Technologies</a>
        </li> -->
        <!-- <li>
            <div class="dropdown">
                <button type="button" class="dropdown-toggle" data-toggle="dropdown">
                    Projects
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item" href="project.html">Project</a>
                    <a class="dropdown-item" href="project-details.html">Project Details</a>
                </div>
            </div>
        </li> -->
        <!-- <li>
            <a href="team.html">Team</a>
        </li> -->
        <!-- <li>
            <div class="dropdown">
                <button type="button" class="dropdown-toggle" data-toggle="dropdown">
                    Pages
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item" href="elements.html">Elements</a>
                </div>
            </div>
        </li> -->
        <!-- <li>
            <div class="dropdown">
                <button type="button" class="dropdown-toggle" data-toggle="dropdown">
                    Blog
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item" href="blog.html">Blog</a>
                    <a class="dropdown-item" href="single-blog.html">Blog Details</a>
                </div>
            </div>
        </li> -->
        <li>
            <a class="toggle_icon" href="/contact">Contact</a>
        </li>
    </ul>
</div>
<!--================ End Offcanvus Menu Area =================-->

<!--================ Canvus Menu Area =================-->
<div class="canvus_menu">
    
    <div class="container">
        <div class="toggle_icon" title="Menu Bar">
                
            <span></span>
            
        </div>
        <!-- <div class="logo">
                <a routerLink="/">
                    <img src="../assets/img/bluelogo.jpg" width="15%" alt="">
                </a>
            </div> -->
    </div>
</div>

<!--================ End Canvus Menu Area =================-->
