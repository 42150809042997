	<!--================Home Banner Area =================-->
	<section class="banner_area ">
            <div class="banner_inner overlay d-flex align-items-center">
                <div class="container">
                    <div class="banner_content text-left">
                        <div class="page_link">
                            <a href="/">Home</a>
                            <a href="/services">Service</a>
                        </div>
                        <h2>Services</h2>
                    </div>
                </div>
            </div>
        </section>
        <!--================End Home Banner Area =================-->
    
        <!--================ Start Service Area =================-->
        <section class="service-area section-gap">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-9 text-center">
                        <div class="section-title">
                            <p>We Providing these services</p>
                            <h1>We At <span>Q & P</span> Provide These <br> Services To Customers</h1>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- single-features -->
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-service">
                            <div class="service-icon">
                                <img style="margin-top:21px" src="../assets/img/services2.webp" alt="">
                                <!-- <img class="s-icon" src="../assets/img/service/icon1.png" alt=""> -->
                                <!-- <img class="clr-icon" src="../assets/img/service/clr-icon1.png" alt=""> -->
                            </div>
                            <div class="service-content">
                                    <h4>Quality Assurance</h4>
                                    <input type="checkbox" class="read-more-state" id="post-1" />
                                    <p class="read-more-wrap" style="text-align:justify">We offer full cycle quality assurance and application testing. 
                                        We add value to your quality assurance practices by<span class="read-more-target"> embedding domain 
                                        expertise and quality into your processes. We focus on increasing the level of 
                                        quality while reducing the overall costs associated with process outputs.</span></p>
                                        <label for="post-1" class="read-more-trigger"></label>
                                    </div>
                        </div>
                    </div>
                    <!-- single-features -->
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-service">
                            <div class="service-icon">
                                <img  src="../assets/img/services1.webp" alt="">
                                <!-- <img class="s-icon" src="../assets/img/service/icon2.png" alt="">
                                <img class="clr-icon" src="../assets/img/service/clr-icon2.png" alt=""> -->
                            </div>
                            <div class="service-content">
                                    <h4>Software Development Automation</h4>
                                    <input type="checkbox" class="read-more-state" id="post-2" />
                                    <p class="read-more-wrap" style="text-align:justify">We build software solutions based on Continuous Delivery, a set of principles and practices 
                                        using Lean and heavy <span class="read-more-target"> automation. By automating traditionally manual processes, 
                                        We empower businesses to focus on growth, collaborating to fulfill technical needs in a 
                                        way that consolidate complex workflows into a simple handoff.</span></p>
                                        <label for="post-2" class="read-more-trigger"></label>
                                    </div>
                        </div>
                    </div>
                    <!-- single-features -->
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-service">
                            <div class="service-icon">
                                <img src="../assets/img/services3.jpg" alt="">
                                <!-- <img class="s-icon" src="../assets/img/service/icon3.png" alt="">
                                <img class="clr-icon" src="../assets/img/service/clr-icon3.png" alt=""> -->
                            </div>
                            <div class="service-content">
                                    <h4>DevOps</h4>
                                    <input type="checkbox" class="read-more-state" id="post-3" />
                                    <p class="read-more-wrap" style="text-align:justify">Our group of Engineers (developers, system admins, QA’s. Testers etc turned into 
                                        DevOps Engineers) will hold end to <span class="read-more-target"> end responsibility of the Application (Software) 
                                        right from gathering the requirement to development, to testing, to infrastructure 
                                        deployment, to application deployment and finally monitoring & gathering feedback 
                                        from the end users, then again implementing the changes.</span></p>
                                        <label for="post-3" class="read-more-trigger"></label>
                                    </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top:30px;">
                    <!-- single-features -->
                    <!-- <div class="col-lg-2 col-sm-12 col-md-2"></div> -->
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-service">
                            <div class="service-icon">
                                <img src="../assets/img/service-4.jpg" alt="">
                                <!-- <img class="s-icon" src="../assets/img/service/icon1.png" alt=""> -->
                                <!-- <img class="clr-icon" src="../assets/img/service/clr-icon1.png" alt=""> -->
                            </div>
                            <div class="service-content">
                                    <h4>Digital Marketing & Solutions</h4>
                                    <input type="checkbox" class="read-more-state" id="post-4" />
                                    <p class="read-more-wrap" style="text-align:justify">Q&P, Digital Marketing Agency aims to define a tight, digital strategy that focuses on achieving your key <span class="read-more-target"> commercial goals. Raising your profile, boosting revenue, increasing efficiency, reducing cost, training your staff and communicating better with your customers are only a few of the business priorities vital to any company’s success. In order to avoid spending extra money for little return, you need a complex yet effective strategy.</span></p>
                                    <label for="post-4" class="read-more-trigger"></label>	
                                </div>
                        </div>
                    </div>
                   <!-- single-features -->
                   <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-service">
                        <div class="service-icon">
                            <img src="../assets/img/service-5-iot.jpg" alt="">
                            <!-- <img class="s-icon" src="../assets/img/service/icon2.png" alt="">
                            <img class="clr-icon" src="../assets/img/service/clr-icon2.png" alt=""> -->
                        </div>
                        <div class="service-content">
                                <h4>Internet Of Things</h4>
                                <input type="checkbox" class="read-more-state" id="post-5" />
                                <p class="read-more-wrap" style="text-align:justify">Q&P, Specializes in building smart solutions that empower enterprises to securely capture, combine and <span class="read-more-target"> transform data into actionable insight. We leverage our expertise to deliver solutions to clients developing connected products, and implements Cloud enabled IoT technologies.
                                    With extensive experience in embedded system design and development.</span> 
                                </p>
                                <label for="post-5" class="read-more-trigger"></label>
                            </div>
                    </div>
                </div>

                <!-- single-features -->
                    
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="single-service">
                            <div class="service-icon">
                                <img src="../assets/img/service-recruitment.png" alt="">
                                <!-- <img class="s-icon" src="../assets/img/service/icon1.png" alt=""> -->
                                <!-- <img class="clr-icon" src="../assets/img/service/clr-icon1.png" alt=""> -->
                            </div>
                            <div class="service-content">
                                    <h4>IT Recruitment & Staffing</h4>
                                    <input type="checkbox" class="read-more-state" id="post-6" />
                                <p class="read-more-wrap" style="text-align:justify">Q&P, provide Recruitment and Staffing services to many industries and domain through our 
                                    innovative and customized <span class="read-more-target"> solutions and passionate commitment to research. Ability to understand the hiring strategies, availability of talent and compensation benchmarking makes us proud hiring partner for various industries</span> 
                                 </p>   
                                    <label for="post-6" class="read-more-trigger"></label>	
                                </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        <!--================ End Service Area =================-->
    
        